import React from 'react'
import { urlWithSearchParamsHandler, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ options }) => {
  if (!options || options.isHide) return null

  return (
    <div className={styles.options}>
      <div className={styles.wrap}>
        <div className={styles.info}>
          <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: options.title }} />
          <p className={styles.description}>{options.description}</p>
        </div>
        <div className={styles.list}>
          {options.items &&
            options.items.map(option => (
              <div className={styles.listItem} key={option._key}>
                <div className={styles.listWrap}>
                  <p className={styles.listText}>{option.title}</p>
                  <p className={styles.listInfo}>{option.description}</p>
                  <div className={styles.bottom}>
                    <a href={option.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.listLink}>
                      {option.buttonText}
                    </a>
                    <img src={sanityImageUrl(option.image)} className={styles.listImage} alt={option.image?.caption} />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
