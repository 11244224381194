import React from 'react'
import { prepareParagraph, urlWithSearchParamsHandler, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ biology }) => {
  if (!biology || biology.isHide) return null

  return (
    <div className={styles.biology}>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <div className={styles.info}>
            <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: biology.title }} />
            <p className={styles.description}>{prepareParagraph(biology.description)}</p>
          </div>
          <div className={styles.video}>
            <iframe
              src={`https://www.youtube.com/embed/${biology.youtubeVideoId}`}
              frameBorder="0"
              allowfullscreen=""
            ></iframe>
          </div>
        </div>
        <div className={styles.list}>
          {biology.items &&
            biology.items.map(biology => (
              <div className={styles.listItem} key={biology._key}>
                <img src={sanityImageUrl(biology.image)} className={styles.listImage} alt={biology.image?.caption} />
                <p className={styles.listText}>{prepareParagraph(biology.title)}</p>
                <a href={biology.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.listLink}>
                  {biology.buttonText}
                </a>
              </div>
            ))}
        </div>
        <p className={styles.warning}>{biology.warning}</p>
      </div>
    </div>
  )
}
