import React from 'react'
import styles from './style.module.sass'

export default ({ foodAndDrugAdministration }) => {
  if (!foodAndDrugAdministration || foodAndDrugAdministration.isHide) return null

  return (
    <div className={styles.wrap}>
      <h2 className={styles.title}>{foodAndDrugAdministration.text}</h2>
    </div>
  )
}
